<template>
  <div class="left-nav-board " :class="isCollapsed ? 'sopen' : 'sclose'">
    <div
      class="ln_tog"
      :class="containerClasses().arrowIcon"
      @mouseover="hoverClass = true"
      @mouseout="hoverClass = false"
      @click="toggleLeftNav"
    >
      <font-awesome-icon :icon="isCollapsed ? ['fas', 'chevron-right'] : ['fas', 'chevron-left']" />
    </div>
    <div
      class="ui sidebar vertical left menu overlay visible"
      :class="containerClasses().container"
      style="-webkit-transition-duration: 0.1s; overflow: visible !important"
      @mouseover="handleMouseOver"
      @mouseleave="handleMouseLeave"
    >
      <div class="ln_logo_wrap">
        <img :src="logoUrl" class="ln_logo" alt="logo" />
        <img :src="squareLogoUrl" class="ln_logo_icon" style="display: none" alt="logo" />
      </div>

      <div id="side-panel" class="ui accordion">
        <div
          v-for="(option, j) in optionsPayload"
          :key="j"
          :class="[
            getOptionStyleClasses(option),
            option.title === selectedSectionTitle && !option.hasChildren ? 'active' : '',
          ]"
          @click="handleOptionClick(option, j)"
        >
          <div v-if="option.isSection" class="ln_sec_title">{{ option.title }}</div>
          <div v-if="option.isSection" class="ln_sec_line"></div>
          <div
            v-if="!option.isSection"
            class="title item"
            :class="option.disabled ? 'inactive' : ''"
          >
            <span>
              <font-awesome-icon
                v-if="option.hasChildren"
                :icon="['far', 'chevron-right']"
                class="ln_dropdown icon fl-right"
                :class="option.isExpanded ? 'rotate-chevron' : ''"
              />
            </span>

            <span>
              <font-awesome-icon
                :icon="[`${option.iconPrefix || 'far'}`, `${option.iconName}`]"
                class="ln-icon"
              />
            </span>
            <span :id="'option-' + sanitizeId(option.title)" class="ln_txt">{{
              option.title
            }}</span>

            <div
              v-if="!isViaAccount && option.disabled"
              class="contact-management-container"
              @click="showAccEmailModal(option)"
            >
              <p
                v-if="requestSentSolutions[getActiveSolutionStr(option.title)]"
                :id="'disabled-option-request-sent-' + sanitizeId(option.title)"
              >
                <font-awesome-icon :icon="['fas', 'circle-check']" />
                Request Sent!
              </p>
              <p v-else :id="'disabled-option-request-' + sanitizeId(option.title)">
                Click to learn more
              </p>
            </div>
          </div>
          <div
            v-if="!option.isSection"
            class="content last-section"
            :class="option.isExpanded ? 'show-data' : 'hide-data'"
          >
            <p
              v-for="(child, k) in option.children"
              :key="k"
              class="item"
              :class="getOptionChildStyleClasses(option, child, selectedSectionTitle)"
              @click="handleSubOption($event, option, child)"
            >
              <font-awesome-icon
                :icon="[`${child.iconPrefix || 'fas'}`, `${child.iconName}`]"
                class="ln-icon"
              />
              <span :id="sanitizeId(option.title) + '-' + sanitizeId(child.title)" class="ln_txt">{{
                child.title
              }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <notification-modal
      v-if="showNotificationModal.show"
      class="activate-solution-modal"
      header-text="Request Solution"
      width="587px"
      :enable-click-outside="true"
      cancel-label="CANCEL"
      confirm-label="CONFIRM"
      :notification-msg="ACTIVATE_SOLUTION_TEXT"
      @on-confirm="sendActivateEmail"
      @on-cancel="hideAccEmailModal"
    />
  </div>
</template>

<script>
import { get } from 'vuex-pathify';
import { generatePendoLocationUrl } from '@/helpers/global/url-helpers';
import { isDemoInstance } from '@/util/utility-functions';
import EventBus from '@/adready-vue/helpers/global/event-bus';
import config from '~/config';
import NotificationModal from './elements/notification-modal.vue';
import appConsoleDataLayer from '~/api/app-console-data';

export default {
  components: { NotificationModal },
  props: {
    options: {
      type: Array,
      required: true,
    },
    isMniDashboard: {
      type: Boolean,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
    isCampaignTableExpanded: {
      type: Boolean,
      required: true,
    },
    defaultSelectedSolution: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  data() {
    return {
      showFullView: false,
      optionsPayload: [],
      hoverClass: false,
      selectedSectionTitle: '',
      showNotificationModal: { title: '', show: false },
      ACTIVATE_SOLUTION_TEXT: 'Submit a request that you have interest in adding this solution.',
      activeTitle: '',
    };
  },
  computed: {
    requestSentSolutions: get('common/requestSentSolutions'),
    account: get('common/account'),
    advertiser: get('common/advertiser'),
    currentUserId: get('common/currentUserId'),
    isViaAccount: get('common/isViaAccount'),
    logoUrl() {
      if (this?.account) {
        const { whitelabelEnabled, appConsoleLogoFile } = this?.account?.organization;
        return whitelabelEnabled && appConsoleLogoFile?.id
          ? `${config.ADREADY_URL}/api/files/download/${appConsoleLogoFile.id}?download=false`
          : '/assets/default/dr-400x140.png';
      }
      return '/assets/default/dr-400x140.png';
    },
    squareLogoUrl() {
      if (this?.account) {
        const { whitelabelEnabled, appConsoleSquareLogoFile } = this?.account?.organization;
        return whitelabelEnabled && appConsoleSquareLogoFile?.id
          ? `${config.ADREADY_URL}/api/files/download/${appConsoleSquareLogoFile.id}?download=false`
          : '/assets/default/dr-icon-90x90.png';
      }
      return '/assets/default/dr-icon-90x90.png';
    },
  },
  watch: {
    options: {
      deep: true,
      immediate: true,
      handler(n) {
        const sections = JSON.parse(JSON.stringify(n || []));
        const optPayload = [];
        sections.forEach((s) => {
          if (!s.hideSectionTitle) {
            optPayload.push({ title: s.section, isSection: true });
          }
          if (s.options && s.options.length) {
            s.options.forEach((o) => {
              const opt = { ...o };
              opt.markerCssName = this.trimIt(o.title);
              optPayload.push(opt);
              if (o.hasChildren) {
                opt.children = [];
                o.children.forEach((c) => {
                  const chd = { ...c };
                  chd.markerCssName = this.trimIt(c.title);
                  opt.children.push(chd);
                });
              }
            });
          }
        });

        // Mark first left nav option as auto selected & auto expanded
        // in demo mode mark the solution which is default selected in constants.js

        if (isDemoInstance() && optPayload.length && this.defaultSelectedSolution !== null) {
          const defaultSolutionIndex = optPayload.findIndex(
            (option) => option.id === this.defaultSelectedSolution
          );
          if (defaultSolutionIndex !== -1) {
            const defaultSolution = optPayload[defaultSolutionIndex];

            // Expand the default solution
            defaultSolution.isExpanded = true;

            // Select the first child of the default solution
            if (defaultSolution.children && defaultSolution.children.length > 0) {
              defaultSolution.children[0].isExpanded = true;
            }
          }
        } else if (optPayload.length) {
          const savedPath = localStorage.getItem('selectedPath');
          if (savedPath && savedPath.length > 0) {
            const { parentOption } = JSON.parse(savedPath);
            this.activeTitle = parentOption.title;
          }

          if (this.activeTitle === '') {
            let firstOptionIndex = 0;
            let firstOption = optPayload[firstOptionIndex];
            if (firstOption.isSection) {
              firstOption = optPayload[++firstOptionIndex];
            }

            firstOption.isExpanded = firstOption?.children?.length > 0;

            if (firstOption?.children?.length > 0) {
              const firstChild = firstOption.children[0];
              firstChild.isExpanded = true;
            }
          } else {
            const activeSolutionIndex = optPayload.findIndex(
              (option) => option.title === this.activeTitle
            );
            if (activeSolutionIndex !== -1) {
              const markActive = optPayload[activeSolutionIndex];
              markActive.isExpanded = true;
            }
          }
        }

        this.optionsPayload = optPayload;
        if (optPayload.length) {
          // Raise events for auto selected left nav option
          let selectedOptionIndex = optPayload.findIndex((o) => o.isExpanded);
          if (selectedOptionIndex < 0) {
            selectedOptionIndex = 0;
            if (optPayload[selectedOptionIndex].isSection) {
              ++selectedOptionIndex;
            }
          }

          const selectedOption = optPayload[selectedOptionIndex];
          if (selectedOption?.children?.length > 0) {
            const selectedChild =
              selectedOption.children.find((o) => o.isExpanded) || selectedOption.children[0];
            this.emitLeftNavOptionClick(selectedOption, selectedChild);
          } else {
            this.handleOptionClick(selectedOption, selectedOptionIndex);
          }
        }
      },
    },
    isCampaignTableExpanded: {
      handler() {
        this.handleMouseLeave();
      },
    },
  },
  mounted() {
    EventBus.$on('on-refresh-nav-route-change', this.refreshHandleSubOption);
    EventBus.$on('clearActiveSelection', this.clearActiveSelection);
  },
  beforeDestroy() {
    EventBus.$off('on-refresh-nav-route-change', this.refreshHandleSubOption);
    EventBus.$off('clearActiveSelection', this.clearActiveSelection);
  },

  methods: {
    clearActiveSelection() {
      this.activeTitle = '';
    },
    getOptionStyleClasses(option) {
      let retStr = '';
      if (option.isSection) {
        retStr = 'ln_sec_title_wrap';
      } else if (option.isExpanded) {
        retStr = 'ln_item_wrap expandable open';
      } else {
        retStr = 'ln_item_wrap expandable close';
      }
      if (this.isMniDashboard) retStr += ' custom-mni';
      if (option.markerCssName) {
        retStr = `${retStr} ${option.markerCssName}`;
      }
      return retStr;
    },
    getOptionChildStyleClasses(option, child, selSecTitle) {
      let retStr = '';
      if (`${option.title} // ${child.title}` === selSecTitle) {
        retStr = 'active';
      }
      if (option.markerCssName && child.markerCssName) {
        retStr = `${retStr} ${option.markerCssName}--${child.markerCssName}`;
      }
      return retStr;
    },
    trimIt(str) {
      if (typeof str === 'undefined' || str.length === 0) {
        return undefined;
      }
      str = str.replace(' ', '-');
      str = str.replace('&', 'and');
      str = str.toLowerCase();
      return str;
    },
    hideAccEmailModal() {
      this.showNotificationModal = { title: '', show: false };
    },
    showAccEmailModal(opt) {
      if (!opt.disabled || this.requestSentSolutions[this.getActiveSolutionStr(opt.title)]) {
        return;
      }
      this.showNotificationModal = { title: opt.title, show: true };
    },
    async sendActivateEmail() {
      const response = await appConsoleDataLayer.activateSolution({
        accountName: this?.account?.name,
        advertiserName: this?.advertiser?.name,
        userId: this.currentUserId,
        solutionName: this.showNotificationModal.title,
      });
      if (response?.status === 200) {
        this.$store.set('common/requestSentSolutions', {
          ...this.requestSentSolutions,
          [this.getActiveSolutionStr()]: true,
        });
      }
      this.showNotificationModal = { title: '', show: false };
    },
    getActiveSolutionStr(solutionName) {
      return `${this?.account?.name}_${this?.advertiser?.name}_${solutionName ||
        this.showNotificationModal.title}`;
    },
    handleMouseOver() {
      if (this.isCollapsed && !this.isCampaignTableExpanded) {
        this.showFullView = true;
      }
    },
    /**
      In handleAddAttribute(), we're appending the clicked left navigation item to the body tag for Pendo tracking purposes.
      You can find the detailed requirement in this link: https://digitalremedy.atlassian.net/browse/PS-1224
    */
    handleAddAttribute(selectedArray) {
      const addedClasses = document?.getElementsByTagName?.('body')?.[0]?.className?.split?.(' ');
      const themeClass = addedClasses?.filter((fil) => fil?.includes('theme'));
      document.body.className = `${[
        ...themeClass,
        selectedArray.map((arr) => arr?.split(' ')?.join('-'))?.join('-'),
      ]?.join(' ')}`.toLowerCase();
      const pendoLocationUrl = generatePendoLocationUrl({
        solution: selectedArray[0] || '',
        subSection: selectedArray[1] || '',
        account: this?.account?.name || '',
        advertiser: this?.advertiser?.name || '',
      });
      window?.pendo?.location?.setUrl?.(pendoLocationUrl);
    },
    handleMouseLeave() {
      if (this.isCollapsed && !this.isCampaignTableExpanded) {
        this.showFullView = false;
      }
    },
    containerClasses() {
      if (!this.isCollapsed) {
        const hoverClass = this.hoverClass ? 'open grow' : 'open';
        const hoverIcon = this.hoverClass ? 'open hoverIcon' : 'open';
        return { container: hoverClass, arrowIcon: hoverIcon };
      }
      if (this.showFullView && this.isCollapsed) {
        return { container: 'hovered', arrowIcon: 'z0 displayNone' };
      }
      return {
        container: `closed ${this.hoverClass ? 'grow2' : ''} very thin icon`,
        arrowIcon: 'closed',
      };
    },
    toggleLeftNav() {
      this.$emit('on-left-nav-toggle', !this.isCollapsed);
    },
    handleOptionClick(opt, index) {
      if (opt.isSection) {
        return;
      }
      if (opt?.children?.length > 0) {
        this.optionsPayload[index].isExpanded = !this.optionsPayload[index].isExpanded;
        return;
      }
      if (opt.disabled) {
        return;
      }
      if (this.isCollapsed) {
        this.handleMouseLeave();
        this.hoverClass = false;
        this.$emit('on-left-nav-toggle', !this.isCollapsed);
      }
      this.emitLeftNavOptionClick(opt);
    },
    refreshHandleSubOption(options) {
      const { parentOption, childOption } = options;
      if (this.isCollapsed) {
        this.handleMouseLeave();
        this.hoverClass = false;
        this.$emit('on-left-nav-toggle', !this.isCollapsed);
      }
      this.$store.set('dashboard/isConversionDisabled', false);
      this.emitLeftNavOptionClick(parentOption, childOption);
    },
    handleSubOption(event, parentOption, childOption) {
      event.preventDefault();
      event.stopPropagation();
      if (this.isCollapsed) {
        this.handleMouseLeave();
        this.hoverClass = false;
        this.$emit('on-left-nav-toggle', !this.isCollapsed);
      }
      this.$store.set('dashboard/isConversionDisabled', false);
      this.emitLeftNavOptionClick(parentOption, childOption);
    },
    emitLeftNavOptionClick(parentOption, childOption) {
      const titles = [];
      titles.push(parentOption.title);

      if (childOption) {
        titles.push(childOption.title);
        if (childOption.title === 'Overview') {
          this.$store.set('dashboard/selectedDashboardTab', 'overview');
        }
      }

      this.selectedSectionTitle = titles.join(' // ');
      this.handleAddAttribute(titles);
      this.$emit('option-click', parentOption, childOption);
    },
    sanitizeId(id) {
      // Regex to remove any non-alphanumeric characters, spaces, and special symbols
      return id?.replace(/[^a-zA-Z0-9_-]/g, '')?.toLowerCase();
    },
  },
};
</script>
<style lang="scss">
/*------scroll bar---------------------*/
.fa-circle-check {
  color: #15aaad;
  margin-right: 4px;
}
@media (max-width: 900px) {
  .ui.visible.left.overlay.sidebar {
    left: -110% !important;
  }
  .body-content {
    padding: 30px 20px 40px 20px !important;
    max-width: 100% !important;
    overflow: hidden;
  }
  .ln_tog {
    display: none;
  }
}
::-webkit-scrollbar {
  width: 5px !important;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: rgba(200, 212, 220, 0.6);
  border: 0px none #ffffff;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #536176 !important;
}
::-webkit-scrollbar-thumb:active {
  background: #536176 !important;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 2px solid #0c0c0c;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #000;
}
::-webkit-scrollbar-track:active {
  background: #000;
}
::-webkit-scrollbar-corner {
  background: #000;
}
</style>
<style lang="scss" scoped>
.exheader .sopen .title.item.inactive {
  pointer-events: none;
}
.title.item.inactive:hover .contact-management-container {
  opacity: 1;
}

::v-deep .activate-solution-modal {
  .notificationContainer {
    .modal-container {
      min-width: 587px !important;
      .modal-footer {
        text-align: center;
        .action-ftr {
          display: flex;
          align-items: center;
          flex-direction: column;
          .actions-btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            .k-btn {
              .label {
                font-weight: 600;
              }
            }
            .cancel-btn {
              .k-btn {
                &:hover {
                  .label {
                    color: #fff !important;
                    border-color: #48525c !important;
                  }
                }
                border: 1px solid var(--primarycolor) !important;
                background-color: transparent;
                .label {
                  color: var(--primarycolor);
                }
              }
            }
          }
        }
      }
      .modal-body {
        height: 135px;
        .modal-wrapper-box {
          padding: 45px 42px;
          width: max-content;

          .notification-header {
            font-size: 14px;
            text-align: left !important;
          }
        }
      }
      .modal-header {
        margin: 0px 42px;
        padding: 0px;
        height: 75px;
        .header-txt {
          font-size: 16px;
          position: relative;
          top: 19px;
          border-bottom: 4px solid var(--primarycolor);
          width: max-content;
          padding-bottom: 17px;
          color: #48525c !important;
          font-weight: 700;
        }
      }
    }
  }
}
.contact-management-container {
  position: absolute;
  background-color: #e9e9e9;
  padding: 17px 6px;
  z-index: 2;
  text-align: center;
  border-radius: 9px;
  font-weight: 600;
  font-size: 12.6px;
  color: var(--primarydark2);
  opacity: 0;
  width: 100%;
  left: 0px;
  bottom: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.account-popup-left {
  left: 10px;
}
.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.primarycolor {
  color: var(--primarycolor);
}
.left-nav-board {
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  min-width: 320px;
  background-color: #fafafb;
  font-family: 'Manrope', sans-serif;
  font-size: 14px;
  line-height: 1.4285em;
  color: rgba(0, 0, 0, 0.87);
  font-smoothing: antialiased;
}

.sclosed {
  .body-content {
    padding-left: 100px;
    margin: auto;
    max-width: 1170px;
  }
}
.bdc_top {
  margin-bottom: 30px;
}
.bdc_top_right {
  width: 49%;
  float: right;
  margin-top: -9px;
}

.ln_tog {
  position: fixed;
  left: 265px;
  top: 52px;
  width: 25px;
  height: 25px;
  background-color: #fff;
  text-align: center;
  border-radius: 100%;
  line-height: 25px;
  z-index: 104;
  opacity: 1;
  transform: scale(1);
  box-shadow: 0px 4px 10px rgba(20, 70, 150, 0.2);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: rgba(0, 0, 0, 0.87);
  svg {
    height: 1.02em;
  }
  &:hover {
    opacity: 1 !important;
    transform: scale(1.1);
    background-color: var(--primarycolor);
    color: #fff;
    opacity: 1 !important;
    transform: scale(1.1);
    background-color: var(--primarycolor);
    color: #fff;
  }
  &.closed {
    left: 46px;
    opacity: 0.9;
    z-index: 104;
    top: 9px;
    transform: scale(0.8);
    &::before {
      content: '';
      display: block;
      width: 90px;
      height: 66px;
      background-color: transparent;
      position: absolute;
      margin: -15px 0px 0px -73px;
      z-index: -1;
    }
    &:hover {
      left: 50px;
    }
    i {
      transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}
// .sclose {
//   .ln_tog {
//     &:hover {
//       left: 256px;
//     }
//   }
// }
.grow {
  width: 274px !important;
}
.hoverIcon {
  left: 259px !important;
}
.fa-solid,
.fass {
  font-weight: 900;
}

.ui {
  &.visible {
    &.left {
      &.overlay {
        &.sidebar {
          -webkit-transform: translate3d(0%, 0%, 0);
          transform: translate3d(0%, 0%, 0);
        }
      }
    }
    &.sidebar {
      visibility: visible;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  &.left {
    &.overlay {
      &.sidebar {
        -webkit-transform: translate3d(-100%, 0%, 0);
        transform: translate3d(-100%, 0%, 0);
      }
    }
    &.sidebar {
      right: auto;
      left: 0px;
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  &.overlay {
    &.sidebar {
      z-index: 103;
    }
  }
  &.vertical {
    &.menu {
      display: block;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
      .item {
        display: block;
        background: none;
        border-top: none;
        border-right: none;
        & > i {
          &.icon {
            width: 1.18em;
            float: right;
            margin: 0em 0em 0em 0.5em;
            position: relative;
            top: 4px;
            padding: 0em;
            transform: rotate(0deg);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        }
        &:before {
          position: absolute;
          content: '';
          top: 0%;
          left: 0px;
          width: 100%;
          height: 0px;
          background: rgba(34, 36, 38, 0.1);
        }
      }
      .active2 {
        &.item {
          &::before {
            content: '';
            display: inline-block;
            height: 100%;
            width: 4px;
            background-color: var(--primarycolor);
            left: -18px;
          }
        }
      }
      .open {
        .item {
          & > i {
            &.icon {
              transform: rotate(90deg);
            }
          }
        }
      }
      &.thin {
        .active2 {
          &.item {
            &::before {
              left: -7px;
            }
          }
        }
      }
    }
  }
  &.sidebar {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease !important;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: none;
    transition: none;
    will-change: transform;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    height: 100% !important;
    max-height: 100%;
    border-radius: 0em !important;
    margin: 0em !important;
    overflow-y: auto !important;
    z-index: 102;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease !important;
    &.menu {
      .item {
        border-radius: 10px !important;
        margin: 4px 18px;
      }
      .content {
        &.hide-data {
          display: none;
        }
        &.show-data {
          display: block;
        }
        .item {
          border-radius: 0px !important;
          margin: 0px 15px 0px 45px;
          padding: 13px 15px;
          font-size: 0.96em;
          border-left: 2px solid #e4ebf3;
          color: #747d94;
          &:hover {
            border-left: 2px solid var(--primarycolor);
            color: var(--primarydark);
          }
          &.active {
            border-left: 2px solid var(--primarycolor);
            color: var(--primarydark);
          }
        }
      }
    }
    &.thin {
      .item {
        border-radius: 0px !important;
        margin: 4px 7px !important;
        &.logo {
          margin: 38px 7px 37px !important;
        }
      }
      .content {
        display: none !important;
      }
    }
  }
  &.menu {
    font-size: 0.9em;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 1rem 0em;
    font-family: 'Manrope', sans-serif;
    background: #ffffff;
    font-weight: normal;
    border: 0px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    min-height: 2.85714286em;
    .item {
      position: relative;
      vertical-align: middle;
      line-height: 1;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background: none;
      padding: 1.3em 1.14285714em;
      text-transform: none;
      color: #4e5669;
      font-weight: 700;
      letter-spacing: 0.03em;
      -webkit-transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
      transition: background 0.1s ease, box-shadow 0.1s ease, color 0.1s ease;
      &:before {
        position: absolute;
        content: '';
        top: 0%;
        right: 0px;
        height: 100%;
        width: 1px;
        background: rgba(34, 36, 38, 0.1);
      }
    }
  }
  &.accordion {
    .title {
      &:not(.ui) {
        padding: 0.5em 0em;
        font-family: 'Manrope', sans-serif;
        font-size: 1em;
        color: #4e5669;
      }
    }
    .item {
      white-space: nowrap;
      cursor: pointer;
      &:hover {
        background-color: #f5f8fb;
      }
    }
  }
}
.animating.ui.overlay.sidebar,
.ui.visible.overlay.sidebar {
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}
.ui.left.visible.sidebar,
.ui.right.visible.sidebar {
  box-shadow: 0px 0px 40px rgba(71, 95, 150, 0.15);
  border-right: 1px solid rgb(20 32 51 / 12%);
}
.ui.left.sidebar,
.ui.right.sidebar {
  width: 280px;
}
.hovered {
  .ln_item_wrap {
    border-left: 4px solid #fff;
    &.active {
      border-left: 4px solid var(--primarycolor);
      color: var(--primarydark);
    }
    &.active.custom-mni {
      border-left: 4px solid #696c7e;
      color: var(--primarydark);
    }
  }
}
.open {
  .ln_item_wrap {
    border-left: 4px solid #fff;
    &.active {
      border-left: 4px solid var(--primarycolor);
      color: var(--primarydark);
    }
    &.active.custom-mni {
      border-left: 4px solid #696c7e;
      color: var(--primarydark);
    }
  }
}
.ui.accordion .title,
.ui.accordion .accordion .title {
  cursor: pointer;
}
.title {
  &.item {
    padding: 1.3em 1.14285714em !important;
  }
}
.sidebar {
  .accordion {
    .content {
      display: none;
      margin-top: -10px !important;
      padding-top: 10px !important;
      padding-bottom: 20px !important;
      border-bottom: 0px solid #ddd;
    }
    .active {
      .content {
        display: block;
      }
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .item {
    i {
      font-size: 24px;
      margin-top: -5px !important;
    }
  }
}

.light {
  -webkit-text-stroke: 4px white;
}
.ui.accordion .title .ln_dropdown.icon,
.ui.accordion .accordion .title .ln_dropdown.icon {
  display: inline-block;
  float: none;
  opacity: 1;
  width: 1.25em;
  height: 1em;
  margin: 0.2em 0.25rem 0em 0rem;
  padding: 0em;
  font-size: 12px;
  color: #868b90;
  transition: all 0.3s ease;
  transform: rotate(0deg);

  &.rotate-chevron {
    transform: rotate(90deg);
  }
}
i {
  &.icon {
    display: inline-block;
    opacity: 1;
    margin: 0em 0.25rem 0em 0em;
    width: 1.18em;
    height: 1em;
    font-family: 'Icons';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    text-align: center;
    speak: none;
    font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
}
.ui.accordion,
.ui.accordion .accordion {
  max-width: 100%;
  overflow-y: scroll !important;
  height: calc(100vh - 120px);
  overflow-x: hidden;
  padding-bottom: 50px;
}
.marginlefting {
  margin-left: 60px !important;
}
.displaynone {
  display: none !important;
}
.displayblock {
  display: block !important;
}
.item {
  &.logo {
    padding: 0px !important;
    margin: 30px 25px 20px !important;
  }
}
.grow {
  width: 274px !important;
}
.ln_logo_wrap {
  padding: 30px 30px 14px;
  margin-bottom: 0px;
  height: 120px;
}
.ln_logo_icon {
  width: 45px;
}
.ln_dropdown {
  .menu {
    .header {
      padding-top: 3.9px !important;
      padding-bottom: 3.9px !important;
    }
  }
}
.ln-icon {
  color: #868b90;
  font-size: 15px !important;
  position: relative;
  top: 0px;
  left: 0px;
  margin-right: 10px;
  transform: scale(1);
  width: 19px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.active {
  .ln-icon {
    color: #4e5669;
  }
  .droparw {
    transform: rotate(180deg);
  }
  &.brand-grp {
    border-bottom: 0px !important;
  }
}
.active2 {
  .ln-icon {
  }
}
.content {
  .ln-icon {
    opacity: 0.6;
    width: 19px;
    font-size: 12px !important;
    color: #747d94;
  }
}
.ln_logo {
  max-width: 200px;
  width: auto;
  max-height: 70px;
}
.ln_txt {
  opacity: 1;
  position: relative;
}
.grow {
  width: 274px !important;
}
.grow2 {
  width: 65px !important;
}
.ln-btn-wrap {
  position: relative;
  display: none;
  float: right;
  top: -4px;
}
.ln-info {
  font-size: 15px !important;
  margin-right: 6px;
  position: relative;
  top: 2px;
  color: #a7b5c2;
  &:hover {
    color: var(--primarycolor);
  }
}
.ln_acct_wrap {
  padding: 32px 30px;
  border-top: 1px solid #eef1f6;
  position: relative;
  white-space: nowrap;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
// .ln_acct_img {
//   width: 40px;
//   height: 40px;
//   border-radius: 100%;
// }
// .ln_acct_img_wrap {
//   display: inline-block;
// }
// .ln_acct_name {
//   display: block;
//   font-weight: 800;
//   font-size: 1.06em;
//   margin-top: 2px;
// }
// .ln_acct_label {
//   display: block;
//   font-weight: 600;
//   font-size: 11px;
//   color: #9fabb8;
//   line-height: 1em;
// }
.ln_acct_txt {
  display: inline-block;
  margin-left: 10px;
  vertical-align: top;
}
.ln_sec_title_wrap {
  margin: 18px 0px 21px 0px;
  position: relative;
}
.ln_sec_title {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #9ca1ac;
  letter-spacing: 0.15em;
  margin: -9px 0px 0px 28px;
  position: absolute;
  display: inline-block;
  background-color: #fff;
  padding: 0px 4px;
  z-index: 2;
}
.ln_sec_line {
  border-bottom: 1px solid #f0f1f3;
  position: relative;
  z-index: 1;
}
.inactive {
  .ln_txt {
    color: #c7d4db;
  }
  .ln-icon {
    color: #c7d4db;
  }
  .ln-btn-wrap {
    display: inline-block;
  }
}
.thin {
  .ln-icon {
    margin-right: 0px;
    transform: scale(1.2);
  }
  .ln_txt {
    display: none;
    position: absolute;
  }
  .ln_dropdown {
    &.icon {
      display: none !important;
    }
  }
  .ln_logo {
    display: none !important;
  }
  .ln_logo_icon {
    display: inline-block !important;
    margin: 8px 0px 12px -23px;
  }
  .ui {
    &.accordion {
      overflow: hidden !important;
      padding: 0px;
    }
  }
  .ln-btn-wrap {
    display: none !important;
  }
  .ln_acct_txt {
    display: none !important;
  }
  .ln_acct_wrap {
    padding: 30px 10px;
  }
  .ln_sec_title {
    display: none;
  }
}
.z0 {
  z-index: 0 !important;
}
.displayNone {
  display: none;
}
.z100 {
  z-index: 100;
}
.o0 {
  opacity: 0;
}
.o50 {
  opacity: 0.5;
}
.o70 {
  opacity: 0.8;
}
.o80 {
  opacity: 0.8;
}
.o90 {
  opacity: 0.8;
}
.o100 {
  opacity: 1;
}
.nopoint {
  pointer-events: none !important;
}
.inact-color {
  color: #a7b5c2;
}

.ln-btn {
  background-color: var(--primarycolor);
  font-size: 9px;
  padding: 3px 4px;
  color: #fff !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.07em;
  display: inline-block;
  font-weight: 800;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: #536176;
  }
}
.mdn-btn {
  background-color: #536176;
  font-size: 11px;
  padding: 4px 8px;
  color: #fff !important;
  cursor: pointer;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  -moz-appearance: none;
  appearance: none;
  letter-spacing: 0.1em;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
  text-align: center;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background-color: #536176;
  }
  &.outline {
    background-color: transparent;
    color: #536176 !important;
    border: 1px solid #c5d2d8;
    &:hover {
      background-color: transparent;
      color: var(--primarycolor) !important;
      border: 1px solid var(--primarycolor);
    }
  }
}
.btn-icon {
  font-size: 16px;
  margin-right: 3px;
  margin-left: 4px;
  position: relative;
  top: 2px;
}
.btn-size2 {
  font-size: 12px;
  padding: 8px 14px;
}
.dropdown {
  min-width: 150px;
  max-width: 240px;
  display: inline-block;
  border-radius: 6px;
  transition: all 0.3s ease;
  position: relative;
  font-size: 14px;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .select {
    cursor: pointer;
    display: block;
    padding: 8px 20px 8px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .dropdown-menu-multi {
    .drp-grp {
      li {
        max-width: 90%;
      }
    }
  }
}
.droparw {
  font-size: 11px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  line-height: 12px;
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -7px;
}
.dropdown.active:hover,
.dropdown.active {
  border-radius: 4px 4px 0 0;
}
.darkdrp.active:hover,
.darkdrp.active {
  border-radius: 4px 4px 0 0;
  background-color: #383a44;
  border-color: #131619;
}
.dropdown .dropdown-menu,
.dropdown .dropdown-menu-multi {
  position: absolute;
  background-color: #fff;
  width: calc(100% + 2px);
  margin-left: -1px;
  left: 0;
  margin-top: 0px;
  box-shadow: 0px 5px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0 1px 4px 4px;
  overflow: hidden;
  display: none;
  max-height: 338px;
  overflow-y: auto;
  z-index: 999;
  border-right: 1px solid #c6d2d8;
  border-left: 1px solid #c6d2d8;
  border-bottom: 1px solid #c6d2d8;
  margin-left: -1px;
  padding: 0px 0px 10px 0px;
  list-style: none;
}
.dropdown .dropdown-menu li,
.dropdown .dropdown-menu-multi li {
  padding: 10px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-size: 13px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.dropdown .dropdown-menu li:hover,
.dropdown .dropdown-menu-multi li:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
.dropdown .dropdown-menu li:active,
.dropdown .dropdown-menu-multi li:active {
  background-color: #333;
}
.flp-top-drop {
  border: 1px solid #c5d2d8;
  background-color: transparent;
  position: relative;
  top: 2px;
  &:hover {
    border: 1px solid #8fc2d9;
  }
}
.drop-light {
  background-color: #f4f4f4;
  color: #333;
  border: 1px solid #ddd;
  .dropdown-menu {
    background-color: #f4f4f4;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.15);
    li {
      &:hover {
        background-color: #ddd;
      }
    }
  }
}
.btn-wraps-txt {
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.drop-single-show {
  height: auto;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.3);
  overflow: visible;
}

.io-drop {
  padding: 1px 0px;
  background-color: #fff;
  background-color: transparent;
  border: 1px solid #c5d2d8;
}

.drop-single-option {
  border-bottom: 0px solid #eee;
  padding-bottom: 20px;
  padding-top: 20px;
}
.drp-itm {
  padding: 10px 17px;
  text-decoration: none;
  display: block;
  font-size: 13px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.04);
  &:hover {
    color: inherit;
    background-color: rgba(255, 255, 255, 0.04);
  }
}
.mdrp-sub {
  padding: 8px 9px 8px 18px !important;
  background-color: rgba(0, 0, 0, 0.05);
  color: #536176;
  font-size: 12px;
}
.drp-active {
  .mdrp-sub {
    display: block;
  }
}
.drop-ck {
  float: right;
  color: var(--primarycolor);
  border: 0px;
  box-shadow: inset 0px 0px 1px 1px rgba(42, 164, 175, 0.44);
  padding: 2px;
  position: relative;
  top: 2px;
  background: transparent;
  font-size: 13px;
  border-radius: 30px;
}
.dropdown-menu-multi {
  .drop-ck {
    float: none;
    color: var(--primarycolor);
    border: 0px;
    box-shadow: inset 0px 0px 1px 1px rgba(155, 155, 155, 0.44);
    padding: 3px;
    position: relative;
    top: 2px;
    background: transparent;
    font-size: 12px;
    border-radius: 30px;
    display: inline-block;
    margin-right: 10px;
    width: 16px;
    height: 16px;
  }
}
.fdrop-label {
  display: inline-block;
  font-size: 0.82em;
  font-weight: 500;
  position: absolute;
  top: -10px;
  background-color: #ffffff;
  padding: 0px 4px;
  margin-left: -4px;
  color: #969caf;
}
.drp-itm-arw {
  font-size: 16px;
  position: absolute;
  right: 10px;
  margin-top: 2px;
}
.check-off {
  color: #ffffff;
}
.mdrp-sub-itms {
  display: none;
}
.hide {
  display: none !important;
}
.brand-arw {
  float: right;
  pointer-events: none;
  transform: rotate(0deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-size: 18px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}
.acct-drop-name {
  padding: 6px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-weight: 600;
  color: #536176;
  &.sub-brand {
    padding: 3px 10px;
    border-bottom: 0px;
    color: #626c83;
  }
  &:hover {
    color: var(--primarycolor);
  }
  &.dp-tvi {
    &.active {
      color: var(--primarycolor);
      font-weight: 600;
      &::before {
        content: '';
        display: inline-block;
        width: 14px;
        height: 30px;
        background-color: var(--primarycolor);
        position: absolute;
        margin: -6px 0px 0px -20px;
      }
    }
  }
}
.sub-brand {
  &:hover {
    color: #eee;
  }
}
.brand-grp {
  &.active {
    .brand-arw {
      transform: rotate(180deg);
    }
  }
}
.mp-nav-brand-link {
  display: inline-block;
  padding: 2px 8px;
  border: 1px solid #bec4d4;
  border-radius: 4px;
  font-weight: 600;
  &:hover {
    background-color: var(--primarycolor);
    border: 1px solid var(--primarycolor);
    color: #fff;
  }
}
.bl-signout {
  float: right;
  &:hover {
    color: var(--primarycolor);
  }
}
.mp-nav-brand-title2 {
  font-weight: 600;
  color: #536176;
  opacity: 0.6;
}
.mp-nav-div {
  height: 13px;
  border-bottom: 1px solid rgba(0, 20, 50, 0.1);
  margin-bottom: 10px;
}

.hicon {
  .slp-icon {
    width: 29px;
    display: none;
    position: relative;
    top: 6px;
    margin-top: -5px;
  }
}

.acct-drop-names {
  margin-top: 10px;
  max-height: 230px;
  overflow: scroll;
}
.ui[class*='very thin'].left.sidebar,
.ui[class*='very thin'].right.sidebar {
  width: 60px;
}
.fl-right {
  float: right !important;
}
.fl-left {
  float: left;
}

// Account switcher
.currentUser-wrap {
  display: flex;
  text-align: right;
  .user-title {
    font-size: 12px;
    color: #d2d2d2;
  }

  .account-txt {
    cursor: pointer;
  }
  .account-select {
    padding-left: 10px;
    color: #d2d2d2;
    cursor: pointer;
  }
}

.last-section {
  padding-bottom: 50px;
  margin-bottom: 50px;
}
</style>
